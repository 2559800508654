.sell-point-section {
    padding: 10px 15px 25px 20px;
    background: #F6F6F6;
}

.container {
    margin-left: auto;
    margin-right: auto;
}
@media screen and (min-width: 650px) {
    .sale-container {
        max-width: 600px;
    } 
}
@media screen and (min-width: 960px) {
    .sale-container {
        max-width: 900px;
    } 
}
.container h2 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.sell-point-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.sell-point-icon {
    width: 28px;
    height: 28px;
    object-fit: contain;
    object-position: center top;
    margin: 7px 0;
}

.sell-point-description {
    display: block;
    margin: 0 10px 10px 10px;
    text-align: left;
}

@media screen and (min-width: 960px) {
    .sell-point-item {
        flex-direction: column;
        align-items: center;
    }
    .sell-point-icon {
        width: 40px;
        height: 40px;
    }
    .sell-point-description {
        text-align: center;
    }
}  

