.course-content {
    padding: 0.75rem;
    max-width: 735px;
    margin: 0 auto;
}
.course-content .page-header {
    border-bottom: 1px solid #ccc;
    margin-bottom: 24px;
    margin-top: 0;
    padding: 0.75rem;
}
.course-content h1 {
    font-size: 2em;
    color: #363636;
    font-weight: 400;
    line-height: 1.125;
}

/* Course Card */
.card {
    background-color: white;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    color: #4a4a4a;
    max-width: 100%;
    position: relative;
    margin: 0;
}

.card-content {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
}
.card-content img {
    width: 160px;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}
.card-content .detail {
    padding: 8px 16px;
}
.card-content .detail p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #666666;
}
.card-content .detail p:first-child {
    font-size: 16px;
    color: var(--gray);
    line-height: 24px;
}
.card-content .detail .truncate {
    margin-top: 8px;
    margin-bottom: 1em;
    min-height: 48px;
}
.card-content .detail p:last-child {
    margin-top: 8px;
}
.card-content .detail p strong {
    font-size: 20px;
    font-weight: 700;
    color: var(--gray);
}
.normal-price {
    text-decoration: line-through;
}

/* button */
.course-detail-button {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 8px 0;
}
@media screen and (min-width: 768px) {
    .course-detail-button {
        flex-direction: row;
    }
}


/* Course Contents */
.table-container {
    width: 100%;
    padding: 12px;
}
.table {
    width: 100%;
    margin: 0 auto;
    background-color: white;
    color: #363636;
    margin-bottom: 1.5rem;
    line-height: 1.5;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    border-top-color: gray;
}
table th, td {
    text-align: left;
    font-size: 14px;
    border: 1px solid #dbdbdb;
    border-width: 0 0 1px;
    padding: 0.5em 0.75em;
    vertical-align: top;
}
.table thead:hover, tbody tr:hover  {
    background-color: #F5F5F5;
}
.table tr {
    font-size: 14px;
}
.table th {
    border-width: 0 0 2px;
}
.table tbody tr:last-child {
    border-bottom: 2px solid #DBDBDB;
}
