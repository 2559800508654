:root {
  --gray: #4a4a4a;
  --gray-hover: #2c2c2c;
  --gray-light-1: #7a7a7a;
  --gray-light: #E8E8E8;
  --red: #d1294f;
  --red-hover: #941633;
  --shadow-card: 0px 2px 1px rgba(54, 83, 99, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.25);
  --shadow-popover: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'sukhumvit';
  src: url('./fonts/sukhumvit/sukhumvitbold-webfont.eot');
  src: url('./fonts/sukhumvit/sukhumvitbold-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/sukhumvit/sukhumvitbold-webfont.woff2') format('woff2'),
       url('./fonts/sukhumvit/sukhumvitbold-webfont.woff') format('woff'),
       url('./fonts/sukhumvit/sukhumvitbold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}
@font-face {
  font-family: 'sukhumvit';
  src: url('./fonts/sukhumvit/sukhumvitreg-webfont.eot');
  src: url('./fonts/sukhumvit/sukhumvitreg-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/sukhumvit/sukhumvitreg-webfont.woff2') format('woff2'),
       url('./fonts/sukhumvit/sukhumvitreg-webfont.woff') format('woff'),
       url('./fonts/sukhumvit/sukhumvitreg-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'sukhumvit';
  src: url('./fonts/sukhumvit/sukhumvitlight-webfont.eot');
  src: url('./fonts/sukhumvit/sukhumvitlight-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/sukhumvit/sukhumvitlight-webfont.woff2') format('woff2'),
       url('./fonts/sukhumvit/sukhumvitlight-webfont.woff') format('woff'),
       url('./fonts/sukhumvit/sukhumvitlight-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'sukhumvit';
  src: url('./fonts/sukhumvit/sukhumvitreg-webfont.eot');
  src: url('./fonts/sukhumvit/sukhumvitreg-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/sukhumvit/sukhumvitreg-webfont.woff2') format('woff2'),
       url('./fonts/sukhumvit/sukhumvitreg-webfont.woff') format('woff'),
       url('./fonts/sukhumvit/sukhumvitreg-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

a {
  color: var(--red);
  cursor: pointer;
  text-decoration: none;
  transition: none 86ms ease-out;
}

@media screen and (min-width: 1192px) {
  .container {
      max-width: 1152px;
  }
}
@media screen and (min-width: 768px) {
  .container {
      margin: 0 auto;
      max-width: 960px;
      padding-left: 20px;
      padding-right: 20px;
  }
}
.container {
  position: relative;

}
