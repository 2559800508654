.course-section h2 {
    color: white;
    text-align: center;
    margin: 2px auto 0 auto;
}
.all-course-section {
    margin: 0 auto;
    padding: 0 0 6px 0;
    background-color: #F6F6F6;
}
.all-course-section h1 {
    text-align: center;
    margin: 0 auto;
    padding: 25px 0;
}
.all-course-section h2 {
    text-align: center;
    margin: 0 auto;
    padding: 20px;
}
.all-course-section .grid {
    column-gap: 0;
}
/* search seciton */
.search-section {
    max-width: 505px;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
}
.search-form {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
}
.search-form h2 {
    text-align: center;
    margin: 14px auto;
}
/* tab */
.tab {
    margin: 0 12px 6px 12px;
    border-bottom: 1px solid #dbdbdb;
}
.tab ul {
    list-style: none outside;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    overflow-x: scroll;
    width: 100%;
    padding: 0;
    margin: 0;
}
.tab li {
    padding: 0.5em 1em; 
    min-width: fit-content;
    font-family: 'sukhumvit', sans-serif;
    font-size: 1em;
    color: #4a4a4a;
}
.tab li:hover {
    background-color: #ebebeb;
    transition: background-color 0.2s ease;
    cursor: pointer;
}
.tab li.is-active {
    color: var(--red);
    font-weight: 600;
    border-bottom: 2px solid var(--red);
}
/* Course Item */
.course-card {
    background-color: white;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    color: #4a4a4a;
    max-width: 100%;
    position: relative;
    margin: 6px 12px;
}
.course-card-content {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
}
.course-card-content img {
    width: 160px;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}
.course-card-content .no-thumbnail {
    width: 160px;
    height: 100%;
    background-color: #ebebeb;
    font-family: 'sukhumvit';
    font-size: 25px;
    font-weight: bold;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.course-card-content .detail {
    padding: 8px 16px;
}
.course-card-content .detail p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #666666;
}
.course-card-content .detail p:first-child {
    font-size: 16px;
    color: var(--gray);
    line-height: 24px;
}
.course-card-content .detail .truncate {
    margin-top: 8px;
    margin-bottom: 1em;
    min-height: 48px;
}
.course-card-content .detail p:last-child {
    margin-top: 8px;
}
.course-card-content .detail p strong {
    font-size: 20px;
    font-weight: 700;
    color: var(--gray);
}
@media screen and (min-width:769px) {
    .all-course-section {
        padding: 0 0 12px 0;
    }
    .tab {
        margin: 0 12px 12px 12px;
    }
    .course-card {
        margin: 12px 12px;
    }
    .course-card-content img {
        height: auto;
        width: 100%;
    }
    .course-card-content .detail p:first-child {
        font-size: 18px;
    }
    .course-card-content .detail p:last-child {
        text-align: center;
    }
    .course-card-content {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }
}