.course-section {
    background-color: var(--gray);
    padding: 20px 0 20px 0;
}

.button-nobg-white {
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 25px;
    padding: 0 18px;
    border: 2px solid white;

    color: white;
    font-family: 'sukhumvit';
    font-weight: bold;
    max-width: fit-content;
}
.button-nobg-white span {
    margin-top: 2px;
}
.button-nobg-white:hover {
    opacity: 0.8;
}
.button-nobg-white svg {
    height: 15px;
    fill: white;
    padding-left: 8px;
}
.course-section .button-nobg-white {
    margin: 30px auto 20px auto;
}

@media screen and (min-width: 1069px) {
    .course-section .button-nobg-white {
        margin: 40px auto 30px auto;
    }
}
