.footer {
    background: var(--gray);
    padding: 10px 0;
    color: #FFF;
    width: 100%;
    bottom: 0;
    display: block;
}
.footer p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: #FFF;
    margin: 0;
    padding: 0;
    line-height: 1.5;
}
.footer a:hover {
    color: rgb(183, 183, 183);
}
.column {
    display: block;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    padding: 0.75rem;
}
.columns {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    margin-top: -0.75rem;
}
.columns:last-child {
    margin-bottom: -0.75rem;
    }
@media screen and (min-width: 768px) {
    .columns {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .column.is-12 {
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
        width: 100%;
    }
    .column.is-6 {
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
        width: 50%;
    }
}