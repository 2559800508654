.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  gap: 24px;
  background: #FFFFFF;

  min-height: calc(100vh - 196px);
}
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 24px;

  width: 100%;
  background: #FFFFFF;
}
.contact-container h2 {
  margin: 0;
}
.contact-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  gap: 12px;
}

@media screen and (min-width: 500px) {
  .contact-section {
    justify-content: center;
    background: #F6F6F6;
    padding: 36px 0px;
    min-height: calc(100vh - 148px);
  }
  .contact-container {
    background: #FFFFFF;
    border-radius: 8px;
    width: fit-content;
  }
}
@media screen and (max-height: 700px) {
  .contact-section {
    display: flex;
    justify-content: flex-start;
  }
}