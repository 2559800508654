#dashboard-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}
.nav.has-shadow {
    box-shadow: 0 2px 3px rgba(10,10,10,0.1);
}
.nav {
    -webkit-box-align: stretch;
    align-items: stretch;
    background-color: white;
    display: flex;
    min-height: 3.5rem;
    text-align: center;
}
.nav .nav-container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 3.5rem;
    width: 100%;
    max-width: 1194px;
    margin: 0 auto;
}

.nav-left, .nav-right {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.nav-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
}
.nav-container .button.login-bn {
    display: flex;
    align-items: center;
    border-radius: 30px;
    font-weight: bold;
    padding-top: 2px;
}
.nav-container .button.login-bn.is-active {
    display: none;
}
@media screen and (min-width: 768px) {
    .nav-right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .nav-container .button.login-bn {
        display: none;
    }
}
.nav-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
@media screen and (max-width: 768px) {
    .nav-menu {
    background-color: white;
    box-shadow: 0 4px 7px rgba(10, 10, 10, 0.1);
    left: 0;
    display: none;
    right: 0;
    top: 100%;
    position: absolute;
    }
    .nav-menu.is-active {
        display: block;
    }
}
@media screen and (min-width: 768px) {
    .nav-toggle {
        display: none;
    }
}
.nav-toggle {
    cursor: pointer;
    height: 3.5rem;
    position: relative;
    width: 3.5rem;
}
.nav-toggle:hover {
    background-color: whitesmoke;
}
.nav-toggle span:nth-child(1) {
    margin-top: -6px;
}
.nav-toggle span:nth-child(2) {
    margin-top: -1px;
}
.nav-toggle span:nth-child(3) {
    margin-top: 4px;
}
.nav-toggle span {
    background-color: #4a4a4a;
    display: block;
    height: 1px;
    left: 50%;
    margin-left: -7px;
    position: absolute;
    top: 50%;
    transition: 86ms ease-out;
    width: 15px;
}
.nav-toggle.is-active span {
    background-color: var(--red);
}
.nav-toggle.is-active span:nth-child(1) {
    margin-left: -5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
}
.nav-toggle.is-active span:nth-child(2) {
    opacity: 0;
}
.nav-toggle.is-active span:nth-child(3) {
    margin-left: -5px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
}
.nav-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-direction: row;
    flex-shrink: 0;
    font-size: 1rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0.5rem 0.75rem;
}
.nav-left .nav-item {
    padding: 0 0.75rem;
}
.nav-item img {
    max-height: 2.6rem;
}
a.nav-item {
    color: var(--gray-light-1);
    font-family: 'sukhumvit';
    font-weight: bold;
}
a.nav-item:hover {
    color: var(--gray);
}
.nav-container .button.is-primary.is-outlined {
    border: 2px solid var(--gray-light-1);
    background-color: white;
    color: var(--gray-light-1);
}
.nav-container .button.is-primary.is-outlined:hover {
    border: 2px solid var(--gray);
    background-color: var(--gray);
    color: white;
}
.nav-container .button.begr-button {
    font-family: 'sukhumvit', sans-serif;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 25px 8px;
    border: none;
    border-radius: 50px;
    line-height: 1.3;
    width: 100%;
}
.nav-container .button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: none;
    box-shadow: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 1rem;
    height: 2.285em;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    line-height: 1.5;
    padding-left: 0.75em;
    padding-right: 0.75em;
    position: relative;
    vertical-align: top;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
    border: 1px solid #dbdbdb;
    color: #363636;
    cursor: pointer;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0.75em;
    padding-right: 0.75em;
    text-align: center;
    white-space: nowrap;
    font-family: 'sukhumvit';
}
@media screen and (max-width: 768px) {
    .nav-menu .nav-item {
        padding: 0.75rem;
        border-top: 1px solid rgba(219, 219, 219, 0.5);
    }
    .nav-item {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        flex-direction: column;
    }
}