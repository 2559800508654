.form-input {
    display: flex;
    padding: 0 5px 0 15px;
    margin: 10px 15px;
    border: 2px solid var(--gray);
    border-radius: 30px;
    align-items: center;
}
.form-input button {
    cursor: pointer;
}
.search-form input {
    font-size: 1em;
    width: 100%;
    background-color: white;
    border: none;
    border-radius: 30px;
}
.search-form input:focus {
    outline: none;
}
.search-form button {
    border-radius: 20px 0 0 20px;
    border: none;
    padding: 9px 9px 9px 0;
    background-color: white;
}
.search-icon {
    height: 22px;
}
.form-input .clear-button {
    padding: 0;
    margin-right: 10px;
}
.clear-button svg {
    height: 18px;
    fill: #c1c1c1;
}
.clear-button:hover svg {
    fill: var(--gray);
}