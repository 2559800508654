* {
  box-sizing: border-box;
}
.App {
  margin-top: 3.5rem;
}
.app-content {
  min-height: calc(100vh - 148px);
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'sukhumvit', sans-serif;
  color: var(--gray);
  
}

h1 {
  font-size: 30px;
}
h2 {
  font-size: 25px;
}

p {
  color: var(--gray);
  font-size: 16px;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 25px;
  padding: 0 18px;
}
.grid, .grid-2 {
  display: grid;
}
.grid-2 {
  grid-template-columns: 1fr 1fr;
}
@media screen and (min-width: 769px) {
  .grid-ipad-3 {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 18px;
  } 
  .grid-ipad-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }  
}
@media screen and (min-width: 960px) {
  .grid-desktop-1 {
    grid-template-columns: 1fr;
    column-gap: 18px;
    max-width: 496px;
    margin-left: auto;
    margin-right: auto;
  } 
  .grid-desktop-2 {
    grid-template-columns: 1fr 1fr;
    column-gap: 18px;
  } 
  .grid-desktop-3 {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 18px;
  } 
  .grid-desktop-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }  
}
