.course-not-found {
  height: calc(100vh - 148px);
  background-color: #FBFBFB;
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.course-not-found .button {
  box-sizing: border-box;
  font-family: 'sukhumvit', sans-serif;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 25px 8px;
}
.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
@media screen and (min-width: 769px) {
  .button-group {
    flex-direction: row;
    
  }
}
.button svg {
  margin-left: 10px;
}
.button-primary {
  background-color: var(--gray);
  border: 2px solid var(--gray);
  color: white;
}
.button-primary:hover {
  background-color: var(--gray-hover);
}
.button-primary svg {
  fill: white;
}

.button-thirdary {
  background-color: white;
  border: 2px solid var(--gray);
  color: var(--gray);
}
.button-thirdary svg {
  fill: var(--gray);
}
.button-thirdary:hover {
  background-color: #FBFBFB;
  border: 2px solid var(--gray-hover);
  color: var(--gray-hover);
}
.button-thirdary:hover svg {
  fill: var(--gray-hover);
}