.contact-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 295px;

  background: #F6F6F6;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
}
.contact-button:hover {
  border: 1px solid #273940;
  transition: 0.05s;
}
.contact-button .icon-hover {
  display: none;
  width: 20px;
  height: 20px;
}
.contact-button .icon {
  display: block;
  width: 20px;
  height: 20px;
  filter: saturate(0) brightness(50%);
}
.contact-button:hover .icon-hover {
  display: block;
}
.contact-button:hover .icon {
  filter: saturate(100%);
  
}
.contact-button .title {
  font-family: 'sukhumvit';
  font-weight: bold;
  width: 100%;
  margin: 0;
  padding-top: 4px;
}
.contact-button .arrow-container {
  display: flex;
  justify-content: flex-end;
}
.contact-button .arrow-container svg {
  height: 15px;
  fill: #273940;
}
.contact-button:hover .arrow-container svg {
  transform: translateX(5px);
  transition: 0.1s;
}

/* course button */
.contact-button.course{
  background: #273940;
  border: 1px solid #273940;
  border-radius: 8px;
}
.contact-button.course:hover {
  background: #1b282d;
}
.contact-button.course svg {
  height: 15px;
  fill: white;
}
.contact-button.course:hover svg {
  scale: 1.05;
  transition: 0.1s;
}
.contact-button.course strong {
  color: white;
}