.testimonial-section {
    margin: 26px 16px;
}
.testimonial-review {
    background-color: #F6F6F6;
    padding: 12px 16px 20px 16px;
    border-radius: 17px;
    box-shadow: var(--shadow-popover);
}
.testimonial-info {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: -35px;
}
.testimonial:nth-child(odd) .testimonial-info {
    flex-direction: row;
}
.testimonial:nth-child(even) .testimonial-info {
    flex-direction: row-reverse;
}
@media screen and (min-width: 960px) {
    .testimonial:nth-child(odd) .testimonial-info,
    .testimonial:nth-child(even) .testimonial-info {
        flex-direction: row;
    }
}
.testimonial-info h4 {
    margin-bottom: 0;
}
.testimonial-info img {
    display: inline;
    width: 67px;
    height: 67px;
    object-fit: cover;
    border-radius: 60px;
}

