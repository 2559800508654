.slider {
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100vw;
}
.course-track {
    display: flex;
    margin-top: 10px;
}
.course-track:hover {
    animation-play-state: paused;
}
.slider-item {
    position: relative;
    height: 101px;
    width: 180px;
    margin-left: 10px;
    overflow: hidden;
}
.slider-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: contain;
    object-position: left;
}
.slider-item h4 {
    position: absolute;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 18px;
    color: white;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

    display: none;
}
.slider-item h4 span {
    font-size: 15px;
    line-height: 9px;
}
.course-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0);
    transition: 0.2s ease;;
}
.course-overlay:hover {
    background: rgba(0, 0, 0, 0.512);
}
.white-button {
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-radius: 25px;
    padding: 0 16px;
}
.white-button.animate {
    opacity: 0;
    transform: translateY(50%);
    transition: 0.2s ease;;
}
.course-overlay:hover .white-button.animate {
    opacity: 1;
    transform: translateY(0);
}

.white-button span {
    color: var(--gray);
    font-family: 'sukhumvit', sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin: 0 10px;
}
.white-button img {
    width: 18px;
    position: relative;
}
@media screen and (min-width: 1069px) {
    .course-track {
        margin-top: 20px;
    }
    .slider-item {
        height: 200px;
        width: 357px;
        margin-left: 20px;
    }
    .slider-item h4 {
        font-size: 40px;
        line-height: 30px;
    }
    .slider-item h4 span {
        font-size: 25px;
    }
}