.button-square-primary {
  background-color: var(--red);
  
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;

  gap: 8px;

  box-shadow: var(--shadow-card);
}
.button-square-primary .text {
  color: white;
  font-family:'sukhumvit', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  
  padding-top: 2px;
}
.button-square-primary svg {
  fill: white;
  width: 14px;
  height: 14px;
}
.button-square-primary:hover {
  background-color: var(--red-hover);
}

.button-square-white {
  background-color: white;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;

  gap: 8px;

  box-shadow: var(--shadow-card);
}
.button-square-white .text {
  color: var(--gray);
  font-family:'sukhumvit', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  
  padding-top: 2px;
}
.button-square-white svg {
  fill: var(--gray);
  width: 14px;
  height: 14px;
}
.button-square-white:hover {
  background-color: var(--gray-light);
}
.button-center {
  justify-content: center;
}
.button-full-width {
  flex-grow: 1;
}