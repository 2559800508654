.search-form h1 {
    text-align: center;
    margin: 0 auto;
    padding: 25px 0 5px 0;
}
.search-result {
    background-color: #F6F6F6;
    min-height: calc(100vh - 310.5px);
    padding-bottom: 6px;
}
.search-result .grid {
    column-gap: 0;
}
.search-result h2 {
    text-align: center;
    margin: 0 auto;
    padding: 15px 0;
    color: #a9a9a9;
}

.search-not-found {
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
    padding-bottom: 25px;
    font-size: 18px;
    font-family: sans-serif;
    color: #a9a9a9;
}
.search-not-found h2 {
    color: #a9a9a9;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
}
.search-not-found img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.search-not-found p {
    text-align: center;
    margin-top: 0;
}

.bt-gray {
    background-color: #a9a9a9;
    color: #F6F6F6;
    font-family: 'sukhumvit';
}
.bt-gray:hover {
    background-color: #F6F6F6;
    color: #2C77F2;
    border: 2px solid #2C77F2;
}
.bt-gray svg {
    fill: white;
    margin-left: 10px;
}
.bt-gray:hover svg {
    fill: #2C77F2;
}