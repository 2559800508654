.cover-page-section {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: relative;
}

.cover-page-section h1 {
    position: absolute;
    opacity: 0;
}

.cover-image {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 130%;
}

.bg-container {
    height: 57.1vw;
}

.cover-bg {
    width: 100%;
    height: 42vw;
    max-height: 520px;
    filter: blur(10px);
    object-fit: cover;
}

@media screen and (min-width: 960px) {
    .cover-image {
        width: 100%;
    }
    .bg-container {
        height: 43.9vw;
    }
}

@media screen and (min-width: 1194px) {
    .cover-image {
        width: 1194px;
    }
    .bg-container {
        height: 524.2px;
    }
}