.course-item {
    position: relative;
    width: 290px;
    height: 494px;
    overflow: hidden;
    object-fit: contain;
    object-position: top center;
}
.mobile-image {
    display: block;
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
}
.desktop-image {
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.course-item h3 {
    position: absolute;
    top: 29px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    width: 310px;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 400;
    line-height: 43px;
    text-align: center;
    color: white;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

    display: none;
}
.course-item h3 span {
    font-size: 35px;
    line-height: 15px;
}
.bottom-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.white-button {
    display: flex;
    background-color: white;
    align-items: center;
    height: 50px;
    border-radius: 25px;
    padding: 0 16px;
}
.white-button span {
    color: var(--gray);
    font-family: 'sukhumvit', sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin: 0 10px;
}
.bottom-content p {
    text-align: center;
    color: white;
    font-family: 'sukhumvit', sans-serif;
    font-size: 14px;
    margin: 10px 0;
    padding: 0 15px;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.tutor-text {
    font-weight: bold;
}
@media screen and (min-width: 735px) {
    .course-item {
        width: 555px;
        height: 312px;
    }
    .mobile-image {display: none;}
    .desktop-image {display: block;}
    .course-item h3 {
        left: 25px;
        transform: translateX(0);
        text-align: left;
    }
    .bottom-content {
        bottom: 25px;
        left: 25px;
        flex-direction: row;
        justify-content: flex-start;
    }
    .white-button {
        min-width: fit-content;
    }
    .bottom-content p {
        text-align: left;
        width: 180px;
        margin: 0;
    }
}
@media screen and (min-width: 1069px) {
    .course-item {
        width: 900px;
        height: 506px;
    }
    .course-item h3 {
        font-size: 70px;
        line-height: 52px;
        width: 500px;
        top: 50px;
        left: 50px;
    }
    .course-item h3 span {
        font-size: 50px;
        line-height: 30px;
    }
    .bottom-content {
        bottom: 35px;
        left: 35px;
    }
    .bottom-content p {
        width: 300px;
    }
}

/* swiper style */
.swiper {
    width: 100%;
}

.swiper-wrapper {
    align-items: center;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide {
    width: 290px;
    opacity: 50%;
    transition: opacity 0.2s ease 0.2s;
}
.swiper-slide-active {
    opacity: 100%;
}
.swiper-button-next, .swiper-button-prev {
    color: rgba(255, 255, 255, 0.7);
    transition: color 0.2s ease;
}
.swiper-button-next::after, .swiper-button-prev::after {
    font-size: 25px;
}
.swiper-button-next:hover, .swiper-button-prev:hover {
    color: rgba(255, 255, 255, 1);
}
@media screen and (min-width: 735px) {
    .swiper-slide {
        width: 555px;
    }
}
@media screen and (min-width: 1069px) {
    .swiper-slide {
        width: 900px;
    }
    .swiper-button-next::after, .swiper-button-prev::after {
        font-size: 50px;
    }
}

/* noSwiper */
.notSwipper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 0 16px;
}
.notSwipper .course-item {
    width: 100%;
    height: 150vw;
}
@media screen and (min-width: 735px) {
    .notSwipper .course-item {
        height: 53vw;
    }
}
@media screen and (min-width: 1194px) {
    .notSwipper .course-item {
        width: 1194px;
        height: 649px;
    }
}
